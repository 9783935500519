import React from 'react';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import {careersEnabled, getLink, getRoutes} from "../../components/shared/routes";
import OfferPage from "../../components/shared/OfferPage/OfferPage";
import SALogo from "../../images/Careers/deeplai_career_sales_admin.svg";

const CareersSalesAdmin = () => {
    const routes = getRoutes();

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }
    if (!careersEnabled.salesAdmin) {
        return <RedirectTo url={getLink([routes.Careers])}/>;
    }

    return (
        <OfferPage
            image={SALogo}
            imageAlt={'deeplai career Sales Admin'}
            name={routes.CareersSalesAdmin.pageTitle}
            scopeOfDuties={<><p>sales administration, international (Customer Relationship Management System)</p>
                <p>sales document process management (Proposify System)</p>
                <p>collaboration with other DEEPLAI departments</p>
                <p>build and promote strong, long-lasting customer relationships</p>
                <p>achieve growth KPI’s</p></>}
            ourRequirements={<><p>bachelor degree</p>
                <p>very good knowledge of the English language</p>
                <p>understanding of sales processes</p>
                <p>understanding customer requirements and problems</p>
                <p>ability to collaborate with others and good communication skills</p>
            </>}
            whatYouCanExpectFromUs={<>
                <p>stationary work at office in Lublin</p>
                <p>friendly atmosphere, daily cooperation with open minded people</p>
                <p>various, interesting projects in cutting-edge technologies</p>
                <p>real personal impact on implemented projects</p>
                <p>renumeration package adequate to your experience</p>
                <p>training tailored to your needs and career goals</p>
                <p>flexible working hours</p>
                <p>private medical care, Multisport card</p></>}
        />
    );
};

export default CareersSalesAdmin;
